import {
  signalingTranscriptionStarted,
  signalingTranscriptionStopped,
  speechCaptioned,
} from 'features/transcripts/actions';
import {
  signalingWhiteboardChanged,
  signalingWhiteboardClosed,
  signalingWhiteboardCursorUpdated,
  signalingWhiteboardOpened,
} from 'features/layout/features/content/actions';
import {
  activeSpeakerChanged,
  audioMutedRemotely,
  audioUnmuteAsked,
  screenshareStarted,
  screenshareStopped,
  signalingAspectRatioAdjusted,
  streamStarted,
  streamStopped,
  videoMutedRemotely,
  videoUnmuteAsked,
} from 'features/streaming/actions';
import { ActionCreator } from '@reduxjs/toolkit';
import { SignalingReceivedEventType } from 'services/signaling/types';
import {
  pong,
  signalingConnected,
  signalingDisconnected,
  systemUpdate,
} from 'features/application/actions';
import {
  participantsLimitReached,
  sessionEnded,
  sessionLeft,
  signalingBroadcasterLimitReached,
  signalingEntryAccessAwaitingStarted,
  signalingRoomJoined,
  signalingRoomJoinPaused,
  signalingRoomLocked,
  signalingRoomUnlocked,
  signalingSessionAwaitingStarted,
  signalingSessionEndsSoon,
} from 'features/room/actions';
import {
  signalingAccessRejected,
  signalingBulkAccessRejected,
  signalingHandLowered,
  signalingHandRaised,
  signalingUserJoined,
  signalingUserKicked,
  signalingUserLeft,
  signalingUserLeftBatch,
  signalingWaitingUserJoined,
  signalingWaitingUserLeft,
  signalingWaitingUserLeftBatch,
  signalingWaitingUsersUpdated,
} from 'features/users/actions';
import {
  signalingRecorderJoined,
  signalingRecordingFailed,
  signalingRecordingRequested,
  signalingRecordingStarted,
  signalingRecordingStopped,
  signalingRecordingStopRequested,
} from 'features/recorder/actions';
import {
  signalingAllChatMessagesReceived,
  signalingChatMessageDeleted,
  signalingChatMessageSent,
} from 'features/chat/actions';
import {
  signalingBroadcastAllowed,
  signalingBroadcastDisallowed,
  signalingEditWhiteboardAllowed,
  signalingEditWhiteboardDisallowed,
  signalingRoleChanged,
  signalingScreenshareAllowed,
  signalingScreenshareDisallowed,
} from 'features/permissions/actions';
import {
  signalingEncryptedNameShared,
  signalingEncryptionKeyShared,
  signalingPublicKeyShared,
} from 'features/e2ee/actions';
import {
  signalingBreakoutRoomAdded,
  signalingBreakoutRoomDeleted,
  signalingBreakoutRoomMessageBroadcast,
  signalingBreakoutRoomRenamed,
  signalingBreakoutRoomsCleared,
  signalingBreakoutRoomsCreated,
  signalingBreakoutRoomsReceived,
  signalingBreakoutRoomsStarted,
  signalingBreakoutRoomsStopped,
  signalingBreakoutUserJoined,
  signalingBreakoutUserLeft,
  signalingBreakoutUserLeftBatch,
  signalingUsersAssignedToBreakoutRoom,
  signalingUserUnassignedFromBreakoutRoom,
} from 'features/breakout-rooms/actions';
import { fileUploadPrepared } from 'features/whiteboard/actions';
import {
  signalingAnswerDeleted,
  signalingQuestionAnswered,
  signalingQuestionAsked,
  signalingQuestionDeleted,
  signalingQuestionLiveAnswerCancelled,
  signalingQuestionLiveAnswerStarted,
  signalingQuestionLiveAnswerStopped,
  signalingQuestionUpvoted,
  signalingQuestionUpvoteRemoved,
} from 'features/qa/actions';
import { signalingFeatureDisabled, signalingFeatureEnabled } from 'features/feature-set/actions';
import {
  signalingPollCreated,
  signalingPollDeleted,
  signalingPollEdited,
  signalingPollEnded,
  signalingPollLaunched,
  signalingPollResultsShared,
  signalingPollResultsSharingStopped,
  signalingPollsReceived,
  signalingPollVoted,
} from 'features/polls/actions';
import {
  signalingContentLibraryConversionFailed,
  signalingContentLibraryConversionProgress,
  signalingContentLibraryEmbedContentCreated,
  signalingContentLibraryFileClosed,
  signalingContentLibraryFileConverted,
  signalingContentLibraryFileDeleted,
  signalingContentLibraryFileOpened,
  signalingContentLibraryFilePageChanged,
  signalingContentLibraryFileRenamed,
  signalingContentLibraryFileUploadAccepted,
  signalingContentLibraryFileUploaded,
  signalingContentLibraryFolderCreated,
  signalingContentLibraryFolderDeleted,
  signalingContentLibraryFolderRenamed,
  signalingContentLibraryOpenedFilesReceived,
  signalingContentLibraryVideoPlayerStateChanged,
  signalingLibraryReceived,
} from 'features/content-library/actions';

export const eventMap: Record<SignalingReceivedEventType, ActionCreator<any>> = {
  pong,
  streamStarted,
  streamStopped,
  screenshareStarted,
  screenshareStopped,
  activeSpeakerChanged,
  sessionEnded,
  sessionLeft,
  participantsLimitReached,
  userLeft: signalingUserLeft,
  userJoined: signalingUserJoined,
  connected: signalingConnected,
  roomJoined: signalingRoomJoined,
  disconnect: signalingDisconnected,
  audioMutedRemotely,
  askedToUnmuteAudioRemotely: audioUnmuteAsked,
  videoMutedRemotely,
  askedToUnmuteVideoRemotely: videoUnmuteAsked,
  recordingStarted: signalingRecordingStarted,
  recordingRequested: signalingRecordingRequested,
  recordingStopRequested: signalingRecordingStopRequested,
  recordingFailure: signalingRecordingFailed,
  recordingStopped: signalingRecordingStopped,
  recorderJoined: signalingRecorderJoined,
  chatMessageSent: signalingChatMessageSent,
  chatMessageDeleted: signalingChatMessageDeleted,
  allChatMessagesReceived: signalingAllChatMessagesReceived,
  transcriptionStarted: signalingTranscriptionStarted,
  transcriptionStopped: signalingTranscriptionStopped,
  speechCaptioned,
  handRaised: signalingHandRaised,
  handLowered: signalingHandLowered,
  kicked: signalingUserKicked,
  broadcastAllowed: signalingBroadcastAllowed,
  broadcastDisallowed: signalingBroadcastDisallowed,
  screenshareAllowed: signalingScreenshareAllowed,
  screenshareDisallowed: signalingScreenshareDisallowed,
  roleChanged: signalingRoleChanged,
  systemUpdate,
  sessionNotStarted: signalingSessionAwaitingStarted,
  encryptionKeyShared: signalingEncryptionKeyShared,
  entryAccessRequired: signalingEntryAccessAwaitingStarted,
  roomLocked: signalingRoomLocked,
  roomUnlocked: signalingRoomUnlocked,
  waitingUserJoined: signalingWaitingUserJoined,
  waitingUserLeft: signalingWaitingUserLeft,
  waitingUserLeftBatch: signalingWaitingUserLeftBatch,
  waitingUsersUpdated: signalingWaitingUsersUpdated,
  bulkUserAccessRejected: signalingBulkAccessRejected,
  accessRejected: signalingAccessRejected,
  publicKeyShared: signalingPublicKeyShared,
  encryptedNameShared: signalingEncryptedNameShared,
  whiteboardOpened: signalingWhiteboardOpened,
  whiteboardClosed: signalingWhiteboardClosed,
  whiteboardUpdated: signalingWhiteboardChanged,
  whiteboardCursorUpdated: signalingWhiteboardCursorUpdated,
  editWhiteboardAllowed: signalingEditWhiteboardAllowed,
  editWhiteboardDisallowed: signalingEditWhiteboardDisallowed,
  roomJoinPaused: signalingRoomJoinPaused,
  broadcasterLimitReached: signalingBroadcasterLimitReached,
  fileUploadPrepared,
  breakoutRoomsCreated: signalingBreakoutRoomsCreated,
  breakoutRoomAdded: signalingBreakoutRoomAdded,
  breakoutRoomsReceived: signalingBreakoutRoomsReceived,
  breakoutRoomsCleared: signalingBreakoutRoomsCleared,
  breakoutRoomDeleted: signalingBreakoutRoomDeleted,
  breakoutRoomRenamed: signalingBreakoutRoomRenamed,
  breakoutRoomsStarted: signalingBreakoutRoomsStarted,
  breakoutRoomsStopped: signalingBreakoutRoomsStopped,
  usersAssignedToBreakoutRoom: signalingUsersAssignedToBreakoutRoom,
  userUnassignedFromBreakoutRoom: signalingUserUnassignedFromBreakoutRoom,
  breakoutRoomMessageBroadcasted: signalingBreakoutRoomMessageBroadcast,
  breakoutUserJoined: signalingBreakoutUserJoined,
  breakoutUserLeft: signalingBreakoutUserLeft,
  breakoutUserLeftBatch: signalingBreakoutUserLeftBatch,
  questionAsked: signalingQuestionAsked,
  questionAnswered: signalingQuestionAnswered,
  questionDeleted: signalingQuestionDeleted,
  answerDeleted: signalingAnswerDeleted,
  questionLiveAnswerStarted: signalingQuestionLiveAnswerStarted,
  questionLiveAnswerStopped: signalingQuestionLiveAnswerStopped,
  questionLiveAnswerCancelled: signalingQuestionLiveAnswerCancelled,
  featureEnabled: signalingFeatureEnabled,
  featureDisabled: signalingFeatureDisabled,
  pollCreated: signalingPollCreated,
  pollEnded: signalingPollEnded,
  pollLaunched: signalingPollLaunched,
  pollsReceived: signalingPollsReceived,
  pollDeleted: signalingPollDeleted,
  pollVoted: signalingPollVoted,
  pollResultsShared: signalingPollResultsShared,
  pollResultsSharingStopped: signalingPollResultsSharingStopped,
  pollEdited: signalingPollEdited,
  aspectRatioAdjusted: signalingAspectRatioAdjusted,
  questionUpvoted: signalingQuestionUpvoted,
  questionUpvoteRemoved: signalingQuestionUpvoteRemoved,
  sessionEndsSoon: signalingSessionEndsSoon,
  userLeftBatch: signalingUserLeftBatch,
  libraryReceived: signalingLibraryReceived,
  libraryFolderCreated: signalingContentLibraryFolderCreated,
  libraryFolderRenamed: signalingContentLibraryFolderRenamed,
  libraryFileRenamed: signalingContentLibraryFileRenamed,
  libraryFileDeleted: signalingContentLibraryFileDeleted,
  libraryFolderDeleted: signalingContentLibraryFolderDeleted,
  libraryFileUploaded: signalingContentLibraryFileUploaded,
  libraryFileUploadAccepted: signalingContentLibraryFileUploadAccepted,
  libraryFileOpened: signalingContentLibraryFileOpened,
  libraryFileClosed: signalingContentLibraryFileClosed,
  libraryFileConversionFailed: signalingContentLibraryConversionFailed,
  libraryFileConversionProgress: signalingContentLibraryConversionProgress,
  libraryFileConverted: signalingContentLibraryFileConverted,
  libraryFilePageChanged: signalingContentLibraryFilePageChanged,
  libraryWebappCreated: signalingContentLibraryEmbedContentCreated,
  videoPlayerStateChanged: signalingContentLibraryVideoPlayerStateChanged,
  libraryOpenedFilesReceived: signalingContentLibraryOpenedFilesReceived,
};
