import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import {
  selectActiveLibraryType,
  selectContentLibraryOpenedFile,
} from 'features/content-library/contentLibrarySlice';
import { contentLibraryFileOpenRequested } from 'features/content-library/actions';
import { ContentLibraryType } from 'features/content-library/types';

export function* openContentLibraryFileSaga(
  action: ReturnType<typeof contentLibraryFileOpenRequested>
) {
  const { id } = action.payload;

  const openedFile: ReturnType<typeof selectContentLibraryOpenedFile> = yield select(
    selectContentLibraryOpenedFile
  );

  if (id === openedFile?.id) {
    return;
  }

  const activeLibraryType: ContentLibraryType = yield select(selectActiveLibraryType);

  yield call(SignalingSocket.send, {
    event: 'openLibraryFile',
    data: {
      personal: activeLibraryType === 'personal',
      id,
    },
  });
}
