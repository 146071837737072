import { Slide } from 'features/content-library/components/content-types/styled';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import ResponsiveIframe from 'features/content-library/components/ResponsiveIframe';
import React from 'react';

export type EmbedFileProps = {
  file: ContentLibraryOpenedFile;
};

const EmbedFile = React.memo(({ file }: EmbedFileProps) => (
  <Slide sx={{ flexGrow: 1 }}>
    <ResponsiveIframe src={file.url} title={file.name} />
  </Slide>
));

export default EmbedFile;
