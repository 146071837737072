import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import CameraControl from 'features/toolbar/Toolbar/controls/CameraControl';
import MicControl from 'features/toolbar/Toolbar/controls/MicControl';
import RaiseHandControl from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControl';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import WhiteboardControl from 'features/toolbar/Toolbar/controls/whiteboard/WhiteboardControl';
import {
  Inner,
  MenuFixedItems,
  Root,
  ToolbarBg,
  ToolbarNavRoot,
} from 'features/toolbar/Toolbar/styles';
import React, { Fragment } from 'react';
import EndCallControl from '../Toolbar/controls/end-call/EndCallControl';

export const PipToolbar = () => {
  const { hasPermissions } = usePermissions();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);
  const { isFeatureAvailable: raiseHandAvailable } = useRaiseHandControl();
  const { isFeatureAvailable: whiteboardAvailable } = useWhiteboardControl();
  const primaryMenuItems = [];

  if (allowBroadcast) {
    primaryMenuItems.push(
      <Fragment key="broadcast-controls">
        <CameraControl />
        <MicControl />
      </Fragment>
    );
  }

  if (raiseHandAvailable) {
    primaryMenuItems.push(<RaiseHandControl key="raise-hand-control" />);
  }
  if (whiteboardAvailable) {
    primaryMenuItems.push(<WhiteboardControl />);
  }

  primaryMenuItems.push(<EndCallControl key="end-call-control" />);

  return (
    <Root
      placement="bottom"
      orientation="horizontal"
      sx={(theme) => ({
        paddingLeft: theme.layout.innerPadding,
        paddingBottom: theme.layout.innerPadding,
      })}
    >
      <ToolbarBg />
      <Inner orientation="horizontal">
        <ToolbarNavRoot orientation="horizontal">
          <MenuFixedItems orientation="horizontal" style={{ justifyContent: 'center' }}>
            {primaryMenuItems}
          </MenuFixedItems>
        </ToolbarNavRoot>
      </Inner>
    </Root>
  );
};
