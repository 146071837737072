import { usePiPWindow } from 'components/PiP/PiPProvider';
import { selectGridPanelMaximized } from 'features/layout/features/content/contentSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useAppSelector } from 'store/hooks';

export const useGridPanelDragHandle = () => {
  const { isMobile } = useBreakpoints();
  const { pipWindow } = usePiPWindow();
  const gridPanelMaximized = useAppSelector(selectGridPanelMaximized);

  const maximizeGridPanel = gridPanelMaximized || isMobile || !!pipWindow;

  return !maximizeGridPanel;
};
