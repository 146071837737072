import {
  gridPanelFullscreenChanged,
  selectGridPanelMaximized,
} from 'features/layout/features/content/contentSlice';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const GridPanelFullscreenControl = () => {
  const maximized = useAppSelector(selectGridPanelMaximized);
  const isRecorder = useRecorder();

  const { isMobile } = useBreakpoints();

  const dispatch = useAppDispatch();

  if (isRecorder) {
    return null;
  }

  const toggle = () => {
    if (maximized) {
      dispatch(gridPanelFullscreenChanged(false));
    } else {
      dispatch(gridPanelFullscreenChanged(true));
    }
  };

  return isMobile ? null : (
    <GridPanelControl icon={maximized ? 'exit-fullscreen' : 'fullscreen'} onClick={toggle} />
  );
};
