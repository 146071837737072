import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { useAppDispatch } from 'store/hooks';

export const useScreensharePanelCloseControl = () => {
  const dispatch = useAppDispatch();

  // this will be needed in future when there's "close remote screenshare" functionality
  // const { hasPermissions } = usePermissions();

  // const canPresent = hasPermissions(PermissionTypes.presentContentLibrary);

  const controlEnabled = false; //canPresent;

  const handleClick = () => {
    dispatch(gridPanelVoided('screenshare'));
  };

  return { controlEnabled, handleClick };
};

export const ScreensharePanelCloseControl = () => {
  const { controlEnabled, handleClick } = useScreensharePanelCloseControl();

  return controlEnabled ? <GridPanelControl icon="close" onClick={handleClick} /> : null;
};
