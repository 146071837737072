import {
  ContentLibraryBaseItem,
  ContentLibraryFileEntry,
  ContentLibraryFileProcessingMeta,
  ContentLibraryFileProcessingStatus,
  ContentLibraryFiles,
  ContentLibraryFolder,
  ContentLibraryFolders,
  ContentLibraryProcessingMeta,
  ContentLibraryReceivedPayload,
  ContentLibraryState,
  FolderReceivedPayload,
} from 'features/content-library/types';

interface NormalizedData {
  folders: ContentLibraryFolders;
  files: ContentLibraryFiles;
  fileProcessingMeta: ContentLibraryProcessingMeta;
}

export const getContentLibrary = (state: ContentLibraryState, personal: boolean) =>
  personal ? state.libraries.personal : state.libraries.room;

export const normalizeFolder = (folder: FolderReceivedPayload): ContentLibraryFolder => ({
  id: folder.id,
  name: folder.name,
  parentId: folder.parentId || 'root',
  date: folder.date,
  childFolderIds: [],
  childFileIds: [],
  childUploadIds: [],
  personal: folder.personal,
});

export const normalizeFile = <
  T extends Omit<ContentLibraryBaseItem, 'folderId'> & {
    folderId: string | null;
  },
>(
  file: T
): T & { folderId: string } => ({
  ...file,
  folderId: file.folderId ?? 'root',
});

export const createFileProcessingMeta = (
  file: ContentLibraryFileEntry,
  folderId: string
): ContentLibraryFileProcessingMeta => {
  const fileProcessingMeta: ContentLibraryFileProcessingMeta = {
    id: file.id,
    status: file.status as ContentLibraryFileProcessingStatus,
    name: file.name,
    folderId,
    progress: 0,
  };

  return fileProcessingMeta;
};

export const normalizeContentLibrary = (
  state: ContentLibraryState,
  data: ContentLibraryReceivedPayload
) => {
  const library = getContentLibrary(state, data.personal);
  const { folders: libraryFolders } = library;

  const normalizedData: NormalizedData = {
    folders: {
      root: {
        id: 'root',
        name: '/',
        parentId: '',
        childFolderIds: [],
        childFileIds: [],
        childUploadIds: [],
        date: null,
        personal: data.personal,
      },
    },
    files: {},
    fileProcessingMeta: { ...library.fileProcessingMeta },
  };

  // normalize all folders without connecting to parent folders
  for (const folder of data.folders) {
    const normalizedFolder = normalizeFolder(folder);

    // preserve existing upload state
    const existingFolder = libraryFolders[normalizedFolder.id];
    if (existingFolder?.childUploadIds.length > 0) {
      normalizedFolder.childUploadIds = existingFolder.childUploadIds;
    }

    normalizedData.folders[normalizedFolder.id] = normalizedFolder;
  }

  // connect folders to their parent folders
  for (const folder of Object.values(normalizedData.folders)) {
    const parentFolder = normalizedData.folders[folder.parentId];
    if (parentFolder) {
      parentFolder.childFolderIds.push(folder.id);
    }
  }

  for (const file of data.files) {
    const normalizedFile = normalizeFile(file);
    const parentFolder = normalizedData.folders[normalizedFile.folderId];

    normalizedData.files[normalizedFile.id] = normalizedFile;

    if (file.status !== 'completed') {
      const fileProcessingMeta = createFileProcessingMeta(normalizedFile, normalizedFile.folderId);
      normalizedData.fileProcessingMeta[fileProcessingMeta.id] = fileProcessingMeta;
    } else {
      // Remove the existing meta if the file status is 'completed'
      delete normalizedData.fileProcessingMeta[file.id];
      // Clean up childUploadIds if present
      parentFolder.childUploadIds = parentFolder.childUploadIds.filter((id) => id !== file.id);
    }

    // Connect to parent folder
    parentFolder?.childFileIds.push(normalizedFile.id);
  }

  // Preserve existing childUploadIds for each folder
  for (const libraryFolderId of Object.keys(libraryFolders)) {
    const existingFolder = libraryFolders[libraryFolderId];
    if (existingFolder.childUploadIds.length > 0) {
      const normalizedFolder = normalizedData.folders[libraryFolderId];
      if (normalizedFolder) {
        normalizedFolder.childUploadIds = existingFolder.childUploadIds;
      }
    }
  }

  return normalizedData;
};
