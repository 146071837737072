import { Box } from '@mui/material';
import { useGridPanelDragHandle } from 'features/layout/GridPanel/useGridPanelDragHandle';

export const GridPanelContainer = ({
  children,
  id,
}: {
  children: React.ReactNode | React.ReactChild;
  id: string;
}) => {
  const hasDragHandle = useGridPanelDragHandle();

  return (
    <Box
      id={id}
      sx={(theme) => ({
        background: theme.palette.grey['50'],
        position: 'absolute',
        width: `calc(100% - ${hasDragHandle ? '18px' : '8px'})`,
        height: 'calc(100% - 48px)',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
      })}
    >
      {children}
    </Box>
  );
};
