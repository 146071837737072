import { RTCClientInitialized } from 'features/application/actions';
import { selectDeviceType } from 'features/application/applicationSlice';
import { DevMode } from 'features/dev-mode/DevMode';
import { streamMinimized } from 'features/layout/actions';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { screenshareStarted } from 'features/streaming/actions';
import { generalDataUpdated } from 'features/streaming/streamingSlice';

import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import Janus from 'lib/janus';
import { SignalingRoomUser } from 'services/signaling';
import { store } from 'store/store';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { userAgentDetails } from 'utils/userAgentDetails';
import { NO_ROOM_ID } from 'utils/webrtc/errors';
import { RTCClient } from 'utils/webrtc/index';
import { MediaServerConnector } from 'utils/webrtc/MediaServerConnector';
import { PublishingFeed } from 'utils/webrtc/publishing/PublishingFeed';
import { ScreensharingFeed } from 'utils/webrtc/publishing/ScreensharingFeed';
import { ReceivingFeed } from 'utils/webrtc/ReceivingFeed';
import {
  BroadcastingByUserId,
  ConnectionMediaTokens,
  FeedStreamInfo,
  JanusConnection,
  JanusConnectionType,
  PublishingKind,
  PublishingOptions,
  PublishingRoomJoinedMessage,
} from 'utils/webrtc/types';
import webrtcAdapter from 'webrtc-adapter';
import {
  FeedId,
  GeneralStreamingUIState,
  MediaStreamType,
  StreamingStarted,
  SubscribeStreamList,
} from 'features/streaming/types';

export class Client {
  roomJoined: boolean = false;

  roomId?: string;

  roomPin?: string;

  breakoutRoomId?: string;

  mountpointId?: string;

  isMediaServerError: boolean = false;

  supressErrors: boolean = false;

  connections: Record<string, JanusConnection> = {};

  mediaServerConnector: MediaServerConnector = new MediaServerConnector();

  publishingFeed: PublishingFeed = new PublishingFeed();

  screensharingFeed: ScreensharingFeed = new ScreensharingFeed();

  receivingFeed: ReceivingFeed = new ReceivingFeed();

  localFeeds: Record<string, boolean> = {};

  privateId?: number;

  mediaDebug: DevMode = new DevMode();

  userIdByFeedId: Record<FeedId, UserId> = {};

  userIdByScreensharingFeedId: Record<FeedId, UserId> = {};

  broadcastingByUserId: BroadcastingByUserId = {};

  screensharingByUserId: BroadcastingByUserId = {};

  publishOptions: PublishingOptions = {};

  initialSubscriptions?: SignalingRoomUser[];

  initialScreenshareSubscription?: {
    id: UserId;
    feedId: FeedId;
    streams: FeedStreamInfo[];
  };

  isUnloading: boolean = false;

  receiveMode: 'videoroom' | 'streaming' = 'videoroom';

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    /*
    It's a singleton class. Most of the stuff you would want to put in here could
    be safely made static or be called sometime later at execution time
    */

    const deviceInfo = userAgentDetails();
    const eventName = deviceInfo.os.name === 'iOS' ? 'pagehide' : 'unload';
    window.addEventListener(eventName, () => {
      this.isUnloading = true;
    });
  }

  private get uiState(): GeneralStreamingUIState {
    return {
      userIdByFeedId: { ...this.userIdByFeedId },
      broadcastingByUserId: { ...this.broadcastingByUserId },
      screensharingByUserId: { ...this.screensharingByUserId },
    };
  }

  subscribeToServer = (connection: JanusConnection) => {
    this.receivingFeed.attachToConnection(connection);
  };

  initialize = (roomId: string, roomPin: string, mountpointId?: string) => {
    this.roomId = roomId;
    this.roomPin = roomPin;
    this.mountpointId = mountpointId;

    Janus.init({
      debug: process.env.REACT_APP_JANUS_DEBUG === 'true',
      // eslint-disable-next-line react-hooks/rules-of-hooks
      dependencies: Janus.useDefaultDependencies({ adapter: webrtcAdapter }),
      console: logger,
      callback: () => {
        store.dispatch(RTCClientInitialized());
      },
    });
  };

  destroy = () => {
    logger.log('Destroying WebRTC client');

    // this needs to be a copy of connection list because otherwise
    // lookup from recursive calls fails
    const connections = { ...this.mediaServerConnector.connections };

    Object.keys(connections).forEach((handle) => {
      const connection = connections[handle];

      if (!connection.replicatedHandle) {
        if (this.isMediaServerError) {
          this.mediaServerConnector.cleanupConnection(handle);
        } else {
          connection.janus.destroy();
        }
      }
    });

    this.publishingFeed.cleanupJoinMedia();
    this.receivingFeed.subscribedScreenshares = {};

    this.broadcastingByUserId = {};
    this.userIdByFeedId = {};
    this.privateId = undefined;
    this.localFeeds = {};
    this.roomJoined = false;

    this.updateRedux();
  };

  reset = (preserveMediaStates = false) => {
    this.supressErrors = true;

    // reset to initial state to allow for new connections;
    logger.log('Destroying WebRTC client');

    const { preserveVideo, preserveAudio } =
      this.publishingFeed.getResetMediaStates(preserveMediaStates);

    this.publishingFeed.cleanupConnection();
    this.publishingFeed = new PublishingFeed();

    this.screensharingFeed.cleanupConnection();
    this.receivingFeed.cleanupConnection(true);

    // this needs to be a copy of connection list because otherwise
    // lookup from recursive calls fails
    const connections = { ...this.mediaServerConnector.connections };

    Object.keys(connections).forEach((handle) => {
      this.mediaServerConnector.cleanupConnection(handle);
    });

    this.publishingFeed.mediaStates.video.enabled = preserveVideo;
    this.publishingFeed.mediaStates.audio.enabled = preserveAudio;

    this.screensharingFeed = new ScreensharingFeed();
    this.receivingFeed = new ReceivingFeed();

    this.broadcastingByUserId = {};
    this.userIdByFeedId = {};
    this.privateId = undefined;
    this.localFeeds = {};
    this.roomJoined = false;

    this.updateRedux();
  };

  setupPublishState = (options: PublishingOptions = {}, broadcastIntended: boolean = false) => {
    this.publishingFeed.broadcastIntended = broadcastIntended;

    if (this.screensharingFeed.broadcastIntended) {
      this.screensharingFeed.resetMediaStates();
      this.screensharingFeed.pendingReconnect = true;
    }
    this.receivingFeed.resetSimulcastSettings();

    this.publishOptions = options;
    this.publishingFeed.configureStreamingState(options);
  };

  resetPublishOptions = () => {
    this.publishOptions = {};
  };

  setInitialSubscriptions = (users: SignalingRoomUser[]) => {
    this.initialSubscriptions = users;

    const broadcastingStateByUserId: BroadcastingByUserId = {};

    users.forEach((user) => {
      this.userIdByFeedId[user.feedId] = user.id;

      broadcastingStateByUserId[user.id] = {
        audio: user.audioEnabled,
        video: user.videoEnabled,
      };

      // Allow for only a single screenshare at the moment;
      if (user.screensharing) {
        if (user.screenshareFeedId && user.screenshareStreams) {
          this.initialScreenshareSubscription = {
            id: user.id,
            feedId: user.screenshareFeedId,
            streams: user.screenshareStreams,
          };
        }
      }
    });

    this.broadcastingByUserId = broadcastingStateByUserId;

    this.updateRedux();
  };

  attemptPublish = () => {
    if (!this.roomId) {
      throw NO_ROOM_ID();
    }

    if (RTCClient.publishingFeed.broadcastIntended) {
      this.publishingFeed.attachPlugin();
    }

    if (this.screensharingFeed.pendingReconnect) {
      this.screensharingFeed.shareScreen();
    }
    // else {
    //   // TODO: Pay attention to these cleanups;
    //   this.screensharingFeed.cleanupConnection();
    // }
  };

  subscribe = ({ feedId, streams, id }: StreamingStarted, screenshare: boolean = false) => {
    logger.warn(`Trying to subscribe to the feed ${feedId}`);
    if (!this.roomId) {
      throw NO_ROOM_ID();
    }

    if (this.localFeeds[feedId]) {
      logger.warn(`Skipping subscription for the feed ${feedId}`);
      return;
    }

    const subscribeStreams: SubscribeStreamList = [];

    streams.forEach((stream) => {
      subscribeStreams.push({ feed: feedId, mid: stream.mid });
    });

    if (screenshare) {
      this.userIdByScreensharingFeedId[feedId] = id;
    } else {
      this.userIdByFeedId[feedId] = id;
    }

    this.updateRedux();

    this.subscribeConnections(subscribeStreams);
  };

  subscribeToRoomUsers = () => {
    if (!this.roomId) {
      throw NO_ROOM_ID();
    }

    if (this.receiveMode === 'streaming') {
      return;
    }

    const streams = this.prepareInitialSubscriptions();

    this.subscribeConnections(streams);

    this.initialSubscriptions = undefined;

    if (this.initialScreenshareSubscription) {
      store.dispatch(screenshareStarted(this.initialScreenshareSubscription));
      this.initialScreenshareSubscription = undefined;
    }
  };

  subscribeConnections = (streams: SubscribeStreamList) => {
    if (!streams.length) {
      return;
    }

    const connections = Object.values(this.mediaServerConnector.connections).filter(
      (connection) => connection.type === JanusConnectionType.subscription
    );

    if (connections.length) {
      connections.forEach((connection) => {
        this.receivingFeed.requestSubscription(connection.handle, streams);
      });
    } else {
      this.mediaServerConnector.queueStreamSubscription(streams);
    }
  };

  disconnectFeed = (feedId: FeedId) => {
    const userId = this.userIdByFeedId[feedId] || this.userIdByScreensharingFeedId[feedId];

    const maximizedStream = selectMaximizedStream(store.getState());
    if (maximizedStream?.userId === userId) {
      store.dispatch(streamMinimized());
    }

    delete this.userIdByFeedId[feedId];

    this.updateRedux();
  };

  setupLocalFeed = (data: PublishingRoomJoinedMessage) => {
    const userId = selectLocalUserId(store.getState());

    this.localFeeds[data.id] = true;
    this.privateId = data.private_id;
    this.userIdByFeedId[data.id] = userId;

    this.updateRedux();
  };

  setBroadcastingState = (userId: UserId, streams: FeedStreamInfo[]) => {
    this.broadcastingByUserId[userId] ??= {};

    streams.forEach((stream) => {
      eventBus.sendMessage(`${stream.type}Enabled`, { userId });

      this.broadcastingByUserId[userId] = {
        ...this.broadcastingByUserId[userId],
        [stream.type]: true,
      };
    });

    this.updateRedux();
  };

  removeBroadcastingDevice = (userId: UserId, type: MediaStreamType) => {
    if (this.broadcastingByUserId[userId]) {
      eventBus.sendMessage(`${type}Disabled`, { userId });

      this.broadcastingByUserId[userId] = {
        ...this.broadcastingByUserId[userId],
        [type]: false,
      };

      this.updateRedux();
    }
  };

  enableScreensharingMedia = (userId: UserId, streams: FeedStreamInfo[]) => {
    this.screensharingByUserId[userId] ??= {};

    streams.forEach((stream) => {
      this.screensharingByUserId[userId] = {
        ...this.screensharingByUserId[userId],
        [stream.type]: true,
      };
    });

    this.updateRedux();
  };

  disableScreensharingMedia = (userId: UserId, type: MediaStreamType) => {
    if (this.screensharingByUserId[userId]) {
      this.screensharingByUserId[userId] = {
        ...this.screensharingByUserId[userId],
        [type]: false,
      };

      this.updateRedux();
    }
  };

  getPublishingFeed = (kind: PublishingKind) => this[`${kind}Feed`];

  // todo: move to ms connector
  refreshConnectionMediaTokens = (tokens: ConnectionMediaTokens) => {
    Object.values(this.mediaServerConnector.connections).forEach((connection) => {
      if (!connection.replicatedHandle) {
        if (connection.type === JanusConnectionType.publishing) {
          connection.janus.updateAuthToken(tokens.publishing);
        }

        if (connection.type === JanusConnectionType.subscription) {
          connection.janus.updateAuthToken(tokens.subscription);
        }
      }
    });
  };

  setBreakoutRoomId = (roomId: string) => {
    this.breakoutRoomId = roomId;
  };

  private updateRedux = () => {
    const state = this.uiState;
    store.dispatch(generalDataUpdated(state));
  };

  private prepareInitialSubscriptions = () => {
    const streams: SubscribeStreamList = [];

    const deviceType = selectDeviceType(store.getState());

    const videoLimit = deviceType === 'mobile' ? 4 : 49;

    let videoTiles = 0;

    this.initialSubscriptions?.forEach((user) => {
      user.streams?.forEach((stream) => {
        const { feedId } = user;

        const { mid, type } = stream;

        if (stream.type === 'video') {
          if (videoTiles < videoLimit) {
            streams.push({ feed: feedId, mid, options: { substream: 0, temporal: 0 } });
            videoTiles += 1;
          }
        } else {
          streams.push({ feed: feedId, mid, options: { substream: 0, temporal: 0 } });
        }

        this.receivingFeed.media.setKind(feedId, mid, type);
      });
    });

    return streams;
  };
}

export default Client;
