import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Menu from 'components/Menu/Menu';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { GridPanelFullscreenControl } from 'features/layout/GridPanel/GridPanelFullscreenControl';
import { useScreensharePanelCloseControl } from 'features/layout/layouts/ScreensharePanelCloseControl';
import { StreamControlMenuItem } from 'features/streaming/components/StreamControlMenuItem';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useMemo, useState } from 'react';
import { GridPanelName } from 'features/layout/features/content/types';
import {
  useFilePanelCloseControl,
  ContentCloseControl,
} from 'features/content-library/components/ContentPanel/ContentCloseControl';
import {
  useWhiteboardCloseControl,
  WhiteboardCloseControl,
} from 'features/whiteboard/WhiteboardCloseControl';
import { useTranslation } from 'react-i18next';
import { GridPanelControl } from './GridPanelControl';

export const GridPanelControlsContainer = styled(Box)({
  position: 'absolute',
  top: '50px',
  right: '24px',
  zIndex: 999,
});

GridPanelControlsContainer.defaultProps = {
  className: 'grid-panel-controls-container',
};

const closeControlsMap: Record<GridPanelName, React.ComponentType> = {
  whiteboard: () => <WhiteboardCloseControl />,
  contentLibrary: () => <ContentCloseControl />,
  screenshare: () => null,
};

export type GridPanelControlsProps = {
  activePanel: GridPanelName;
};

export const GridPanelControls = ({ activePanel }: GridPanelControlsProps) => {
  const GridPanelCloseControl = useMemo(() => closeControlsMap[activePanel], [activePanel]);

  const { isMobile } = useBreakpoints();

  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => setMenuOpen(false);

  const { t } = useTranslation('room');

  const wbConfig = useWhiteboardCloseControl();
  const filePanelConfig = useFilePanelCloseControl();
  const screenshareConfig = useScreensharePanelCloseControl();

  const config: Record<GridPanelName, { controlEnabled: boolean; handleClick: () => void }> = {
    whiteboard: wbConfig,
    contentLibrary: filePanelConfig,
    screenshare: screenshareConfig,
  };

  const { controlEnabled, handleClick } = config[activePanel];

  const { pipWindow } = usePiPWindow();

  return (
    <GridPanelControlsContainer
      sx={{
        width: isMobile ? '38px' : 'auto',
        height: isMobile ? '38px' : 'auto',
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            position: 'absolute',
            right: '-.25rem',
            top: 0,
            width: '100%',
            textAlign: isMobile ? 'left' : 'center',
            zIndex: 3,
          }}
        >
          {controlEnabled ? (
            <Menu
              id="grid-panel-controls"
              open={menuOpen}
              onClose={closeMenu}
              placement="bottom-end"
              trigger={
                <button
                  type="button"
                  className="button-appearance-none"
                  onClick={() => setMenuOpen((open) => !open)}
                >
                  <GridPanelControl icon="settings1" />
                </button>
              }
              PaperProps={{
                sx: (theme) => ({
                  backgroundColor: theme.palette.grey['900'],
                }),
              }}
              sx={(theme) => ({
                color: theme.palette.common.white,
              })}
            >
              {/*<StreamControlMenuItem icon="box-arrow" text={t('grid.push_content')} />*/}
              <StreamControlMenuItem
                icon="close"
                text={t('grid.close_panel')}
                onClick={handleClick}
              />
            </Menu>
          ) : null}
        </Box>
      ) : (
        <>
          {/*<GridPanelControl icon="box-arrow" />*/}
          {pipWindow ? null : <GridPanelFullscreenControl />}
          <GridPanelCloseControl />
        </>
      )}
    </GridPanelControlsContainer>
  );
};
