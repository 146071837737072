import { Slide, SlideImg } from 'features/content-library/components/content-types/styled';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import React from 'react';

export type SinglePageFileProps = {
  file: ContentLibraryOpenedFile;
};

const SinglePageFile = React.memo(({ file }: SinglePageFileProps) => (
  <Slide sx={{ flexGrow: 1 }}>
    <SlideImg src={file.url} />
  </Slide>
));

export default SinglePageFile;
