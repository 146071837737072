import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { PanelContentSelector } from 'features/layout/GridPanel/PanelContentSelector';
import { screenshareSelected } from 'features/streaming/actions';
import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ScreensharePanel = () => {
  const dispatch = useAppDispatch();
  const [activeScreenshare, setActiveScreenshare] = useState<number | undefined>(undefined);

  const screenshares = useAppSelector(selectScreenshareStreams);

  useEffect(() => {
    if (!screenshares.length) {
      dispatch(gridPanelVoided('screenshare'));
      return;
    }

    setActiveScreenshare(screenshares.length - 1);
  }, [screenshares, dispatch]);

  const activeScreenshareEntry =
    activeScreenshare !== undefined ? screenshares[activeScreenshare] : null;

  const selectScreenshare = (idx: number) => {
    const screenshare = screenshares[idx];
    if (screenshare?.feedId) {
      dispatch(screenshareSelected({ feedId: screenshare.feedId, userId: screenshare.userId }));

      setActiveScreenshare(idx);
    }
  };

  return screenshares.length && activeScreenshareEntry ? (
    <GridPanelContainer id="screenshare-container">
      <PanelContentSelector
        items={screenshares}
        activeItem={activeScreenshareEntry}
        onSelect={selectScreenshare}
        renderLabel={(screenshare) =>
          `Screenshare (${screenshare.kind === 'screenshare-local' ? 'You' : screenshare.user?.name})`
        }
      />

      <StreamDisplay source={activeScreenshareEntry} maximized />
    </GridPanelContainer>
  ) : null;
};
