import { fileViewed } from 'features/content-library/actions';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { PanelContentSelector } from 'features/layout/GridPanel/PanelContentSelector';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import {
  selectContentLibraryOpenedFile,
  selectContentLibraryViewedFiles,
} from 'features/content-library/contentLibrarySlice';
import SinglePageFile from 'features/content-library/components/content-types/SinglePageFile';
import GalleryFile from 'features/content-library/components/content-types/GalleryFile/GalleryFile';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import EmbedFile from 'features/content-library/components/content-types/EmbedFile';
import EmbedVideo from 'features/content-library/components/content-types/EmbedVideoPlayer/EmbedVideo';
import { dispatch } from 'store/store';

export const ContentPanel = ({ size }: { size: { width?: number; height?: number } }) => {
  const { hasPermissions } = usePermissions();
  const files = useAppSelector(selectContentLibraryViewedFiles);

  const openedFile = useAppSelector(selectContentLibraryOpenedFile);

  if (!openedFile) {
    return null;
  }

  const canPresentFiles = hasPermissions(PermissionTypes.presentContentLibrary);

  const renderPanelContent = () => {
    if (canPresentFiles && 'pages' in openedFile) {
      return <GalleryFile file={openedFile} size={size} />;
    }

    if (openedFile.type === 'webapp') {
      return <EmbedFile file={openedFile} />;
    }

    if (openedFile.type === 'youtube' || openedFile.type === 'vimeo') {
      return <EmbedVideo file={openedFile} />;
    }

    return <SinglePageFile file={openedFile} />;
  };

  const selectFile = (idx: number) => {
    dispatch(fileViewed(files[idx].id));
  };

  return (
    <GridPanelContainer id="content-container">
      <PanelContentSelector
        items={files}
        activeItem={openedFile}
        onSelect={selectFile}
        renderLabel={(file) => file.name}
      />
      {renderPanelContent()}
    </GridPanelContainer>
  );
};
