import { PayloadAction } from '@reduxjs/toolkit';
import { findContentForStageSaga } from 'features/layout/sagas/findContentForStageSaga';
import { LayoutMode } from 'features/layout/types';
import { call } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onLayoutModeChangedSaga(action: PayloadAction<LayoutMode>) {
  yield call(eventBus.sendMessage, 'layoutModeChanged', {}, { mode: action.payload });
  yield call(findContentForStageSaga, true);
}
