import { PayloadAction } from '@reduxjs/toolkit';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { screenshareSelected, screenshareStopped } from 'features/streaming/actions';
import { selectSelectedScreenshare } from 'features/streaming/streamingSlice';
import { SelectedScreenshare } from 'features/streaming/types';
import { UserLeftPayload } from 'features/users/types';
import { put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* cleanupSelectedScreenshareSaga(action: PayloadAction<UserLeftPayload>) {
  const selectedScreenshare: SelectedScreenshare | null = yield select(selectSelectedScreenshare);

  if (selectedScreenshare && action.payload.id === selectedScreenshare.userId) {
    const screenshares: SourceDetails[] = yield select(selectScreenshareStreams);

    const { streams } = RTCClient.receivingFeed.media.getFeedStreams(selectedScreenshare.feedId);

    yield put(
      screenshareStopped({
        id: selectedScreenshare.userId,
        feedId: selectedScreenshare.feedId,
        streams: Object.values(streams).map((stream) => ({
          type: stream.kind,
          mid: stream.publisherMid,
        })),
      })
    );

    const remainingScreenshares = screenshares.filter(
      (source) => source.userId !== selectedScreenshare.userId
    );

    if (remainingScreenshares.length > 0) {
      const screenshare = remainingScreenshares[remainingScreenshares.length - 1];

      yield put(screenshareSelected({ feedId: screenshare.feedId!, userId: screenshare.userId }));
    }
  }
}
