import {
  fileOpened,
  selectContentLibraryViewedFiles,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { gridPanelOpened, gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { put, select } from 'redux-saga/effects';

export function* findOpenedFileSaga() {
  const files: ContentLibraryOpenedFile[] = yield select(selectContentLibraryViewedFiles);

  let latestFile: ContentLibraryOpenedFile;

  if (!files?.[0]) {
    yield put(gridPanelVoided('contentLibrary'));
  }

  if (files.length > 1) {
    latestFile = files.reduce((latest, current) => {
      const latestDate = new Date(latest.date);
      const currentDate = new Date(current.date);

      return currentDate > latestDate ? current : latest;
    });
  } else {
    [latestFile] = files;
  }

  if (latestFile) {
    yield put(fileOpened(latestFile));
    yield put(gridPanelOpened('contentLibrary'));
  } else {
    yield put(gridPanelVoided('contentLibrary'));
  }
}
