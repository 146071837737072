import { Box, ListItemText, MenuItem } from '@mui/material';
import Icon from 'components/Icon';
import Menu from 'components/Menu/Menu';
import { Chip } from 'features/streaming/components/stream-display/StreamBadge';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useState } from 'react';

export const PanelContentSelector = ({
  items,
  activeItem,
  onSelect,
  renderLabel,
}: {
  items: any[];
  activeItem: any;
  renderLabel: (item: any) => string;
  onSelect: (idx: number) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);

  const { isMobile } = useBreakpoints();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        width: '100%',
        top: (theme) => theme.spacing(2),
        textAlign: isMobile ? 'left' : 'center',
        padding: (theme) => theme.spacing(0, 2),
        zIndex: 3,
      }}
    >
      <Menu
        id="file-panel-content-selector"
        open={menuOpen}
        onClose={closeMenu}
        placement={isMobile ? 'bottom-start' : 'bottom'}
        trigger={
          <button
            type="button"
            className="button-appearance-none"
            style={{ padding: 0 }}
            onClick={() => {
              if (items.length > 1) {
                setMenuOpen((open) => !open);
              } else {
                setMenuOpen(false);
              }
            }}
          >
            <Chip
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{renderLabel(activeItem)}</span>
                  {items.length > 1 ? (
                    <Icon
                      name="dropdown-arrow"
                      sx={{
                        fontSize: '32px',
                        marginRight: '-10px',
                        transform: 'translateY(-2px)',
                      }}
                    />
                  ) : null}
                </div>
              }
            />
          </button>
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {items.map((row, idx) =>
          row ? (
            <MenuItem key={idx} onClick={() => onSelect(idx)}>
              <ListItemText>{renderLabel(row)}</ListItemText>
            </MenuItem>
          ) : null
        )}
      </Menu>
    </Box>
  );
};
