import {
  selectOrderedStreams,
  selectScreenshareStreams,
} from 'features/layout/features/order/orderSlice';
import { makeSourceKey } from 'features/streaming/utils';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import RemoteAudioWrapper from 'features/streaming/components/media/remote-stream/RemoteAudioWrapper';

export const AudioStreams = () => {
  const orderedStreams = useAppSelector(selectOrderedStreams);
  // keep screenshare here even though it's not a part of the greed so it
  // works well with recorder and different layout combinations

  const screenshareStreams = useAppSelector(selectScreenshareStreams);

  return (
    <div className="audio-streams">
      {orderedStreams.map((stream) => (
        <RemoteAudioWrapper source={stream} key={makeSourceKey(stream)} />
      ))}
      {screenshareStreams.map((stream) =>
        stream ? <RemoteAudioWrapper source={stream} key={makeSourceKey(stream)} /> : null
      )}
    </div>
  );
};
