import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import { GridPanelName, LayoutContentState } from 'features/layout/features/content/types';
import { RootState } from 'store/store';

export const initialState: LayoutContentState = {
  maximizedContent: null,
  maximizedStream: null,
  previouslyMaximizedStream: null,
  expectScreenshareOnStage: false,
  gridPanel: null,
  gridPanelMaximized: false,
  availableTabs: {
    screenshare: false,
    whiteboard: false,
    contentLibrary: false,
  },
};

const layoutContentSlice = createSlice({
  name: 'layoutContent',
  initialState,
  reducers: {
    gridPanelFullscreenChanged(state, action: PayloadAction<boolean>) {
      state.gridPanelMaximized = action.payload;
    },
    gridPanelOpened(state, action: PayloadAction<GridPanelName>) {
      state.gridPanel = action.payload;
      state.availableTabs[action.payload] = true;
    },
    gridPanelClosed(state) {
      if (state.gridPanel) {
        state.availableTabs[state.gridPanel] = false;
        state.gridPanel = null;
      }
    },
    gridPanelVoided(state, action: PayloadAction<GridPanelName>) {
      if (state.gridPanel === action.payload) {
        state.availableTabs[state.gridPanel] = false;
        let nextPanel: GridPanelName | null = null;

        for (const k of Object.keys(state.availableTabs)) {
          if (state.availableTabs[k as GridPanelName]) {
            nextPanel = k as GridPanelName;
            break;
          }
        }

        state.gridPanel = nextPanel;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(streamMaximized, (state, action) => {
      state.maximizedContent = 'stream';
      state.maximizedStream = action.payload.source;
    });
    builder.addCase(streamMinimized, (state) => {
      state.previouslyMaximizedStream = state.maximizedStream;

      state.maximizedContent = null;
      state.maximizedStream = null;
    });
  },
});

export const { gridPanelFullscreenChanged, gridPanelOpened, gridPanelClosed, gridPanelVoided } =
  layoutContentSlice.actions;

export const selectMaximizedStream = (state: RootState) => state.layout.content.maximizedStream;
export const selectPreviouslyMaximizedStream = (state: RootState) =>
  state.layout.content.previouslyMaximizedStream;

export const selectExpectScreenshareOnStage = (state: RootState) =>
  state.layout.content.expectScreenshareOnStage;

export const selectMaximizedContent = (state: RootState) => {
  if (
    state.layout.content.maximizedContent === 'stream' &&
    state.layout.contentDimensions.width !== 1
  ) {
    return null;
  }

  return state.layout.content.maximizedContent;
};

export const selectGridPanel = (state: RootState) => state.layout.content.gridPanel;

export const selectAvailableTabs = (state: RootState) => state.layout.content.availableTabs;
export const selectGridPanelMaximized = (state: RootState) =>
  state.layout.content.gridPanelMaximized;

export default layoutContentSlice.reducer;
