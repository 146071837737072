import { selectedScreenshareClosed } from 'features/streaming/streamingSlice';
import { call, put, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { RTCClient } from 'utils/webrtc';
import { selectRoomStatus } from 'features/room/roomSlice';
import { RoomStatus } from 'features/room/types';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';

export function* prepareReconnectionSaga() {
  const roomStatus: RoomStatus = yield select(selectRoomStatus);
  if (roomStatus !== 'joined') {
    return;
  }

  SignalingSocket.isRoomJoined = false;

  yield call(RTCClient.destroy);

  yield put(selectedScreenshareClosed());

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(SFrameManager.cleanup);
    yield call(E2EEManager.cleanup);
  }
}
